'use strict';
const util = require('../components/util');

module.exports = {
    productTileSlider: function () {
        if (util.isMobile()) {
            $('.js-tile-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false
            });
        } else if ($('.js-tile-slider').hasClass('slick-initialized')) {
            $('.js-tile-slider').slick('unslick');
        }

        if ($('.js-recommendation-carousel').length > 0) {
            $('.js-recommendation-carousel').find('.js-tile-slider').slick('unslick');
        }
    }
};
